.landing {
  display:grid;
  position: relative;
  text-align: center;
  top:110px;
}

.header {
  background-color: transparent;
  
  position: absolute;
  width: 100%;
  z-index: 3;
  font-size: 12px;
 
  font-weight: 500;
}

.header div {

  list-style: none;
  overflow: hidden;
  background-color: transparent;
  font-weight:bolder;
  
}

.header li a {
  display:flex;
  padding: 25px 20px;
  

  text-decoration: none;
  color: #005675;
  background-color: #fff;
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: #a1a5ab;
  color: #fff;
}

.header .logo {
  display: block;
  float: left;
  padding: 10px 0px 0px 10px;
  text-decoration: none;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display:block;
  float: right;
  padding: 12px 24px;
  top: 40px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2.4px;
  position: relative;
  transition: background .2s ease-out;
  width: 20px;
  bottom: 20px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 89%;
  position: relative;
  transition: all .2s ease-out;
  width: 100%;
  
}

.header .menu-icon .navicon:before {
  top: 4px;
}

.header .menu-icon .navicon:after {
  top: -6px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 340px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: white;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  
  .header li {
    float: left;
  }
  .header li a {
    padding: 20px 30px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}

h1 {
  color: #004CBE;
  text-align: center;
}

h2 {
  color: #005675;
 
  
}

/* AQUI TERMINA MENU*/


img {
  margin-left: auto;
  margin-right: auto;
  
}



.titulo-banner {    
  background-color: rgba(18,72,120, 0.7);
  color: #fff;
  padding: 30px;
  position: absolute;
  top: 250px; 
  left: 3rem;
  font-size: 26px;
  font-weight: 600;
}

/* .main-section{
  color: #5e5e5e;
  padding: 40px;
}
 */
.glow-on-hover {
  width: 150px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  
}


.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #5460d4
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background:#5460d4;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.titulo2 {
  color: rgb(37, 69, 100);
  font-weight: bolder;
  font-size: 18px;
  word-spacing: 2px;
}

.video-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.video-container iframe {
  position: absolute;
  top: 25%;
  left: 25%;
  width: 70%;
  height: 70%;
}

.video-containerMobile {
  position: relative;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  
}

.video-containerMobile iframe {
  position: relative;
  top: 160px;
  display: block;
  margin: auto;
  width: 350px;
  height: 220px;
  
  }

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

.login-box {
  position: relative;
  top: 10%;
  display:block;
  width: 500px;
  padding: 20px;
  
  
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  z-index: 1;
  overflow: hidden;
}



.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}


.login-box .user-box {
  position: relative;
}



.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}



.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}


.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}


.login-box a:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 5px #03e9f4, 0 0 5px #03e9f4, 0 0 5px #03e9f4,
    0 0 5px #03e9f4;
}

.login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 10px;
  letter-spacing: 4px;
}


.login-box a span {
  position: absolute;
  display: flex;
  
}


.login-box a span:nth-child(2) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 5s linear infinite;
}



@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 2s linear infinite;
  animation-delay: 0.1s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}


.login-boxMobile {
  position: relative;
  top: 10%;
  display:block;
  width: 300px;
  padding: 20px;
  
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  z-index: 1;
  overflow: hidden;
}



.login-boxMobile h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}


.login-boxMobile .user-box {
  position: relative;
}



.login-boxMobile .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.login-boxMobile a:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 5px #03e9f4, 0 0 5px #03e9f4, 0 0 5px #03e9f4,
    0 0 5px #03e9f4;
}

.login-boxMobile .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}


.login-boxMobile .user-box input:focus ~ label,
.login-boxMobile .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}



.login-boxMobile form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 10px;
  letter-spacing: 4px;
}


.login-boxMobile a span {
  position: absolute;
  display: flex;
  
}


.login-boxMobile a span:nth-child(2) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 5s linear infinite;
}



@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.login-boxMobile a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.login-boxMobile a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 2s linear infinite;
  animation-delay: 0.1s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.login-boxMobile a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}


.datos {
  background-image: linear-gradient(
    90deg,
    #5460d4 0%,
  
  );
  display: grid;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(43, 20, 20);
 
}

.container{
  display: grid;
  justify-content: center;
  align-items: center;
  position:relative;
  margin-top:20px;
  margin-bottom:20px;
  }

  
.containerMobile{
  display: grid;
  justify-content: center;
  align-items: center;
  position:relative;
  margin-top:20px;
  margin-bottom:20px;
  }

  h1 {
   
    animation: createBox 1s;
  
  }
  @keyframes createBox {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  .newBox {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px #674da0;
    border-radius: 10px;
    width: 75%;
  
    margin: auto;
    height: 90%;
    position: relative;
    
    text-align: center;
  
  }
  .App-credits {
 
    color:#004CBE;
    line-height: 30px;
    text-align: center;
    
  
    font-weight: bolder;
  }

 h1, h2, h3, h4, h5{
    font-family: Gotham Black Regular, Sans-serif,
  }

  .scaleCenter {
    -webkit-animation: scaleCenter 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: scaleCenter 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @-webkit-keyframes scaleCenter {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scaleCenter {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }

  .scroll-effect {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }
  
  .scroll-effect.active {
    opacity: 0;
  }